import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {Title, Text} from "./typo.js";
import {t} from "../common/i18n.js";
import Button from "./button.js";
import ProductOption from "./product-option-list-item.js";
import {ButtonArrow} from "./icons.js";
import {spacings, media, colors} from "./theme.js";
import Component from "./editable-component.js";
import Image from "./image.js";
import {withAttrs} from "../common/utils.js";
export default function ProductCard(props) {
  const {headline, copy, color, url, options, image, small, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(ProductCardContainer, {
    href: url.cached_url
  }, /* @__PURE__ */ React.createElement(ProductColorBlock, {
    color: color.color
  }, /* @__PURE__ */ React.createElement(ImageContainer, {
    ...image,
    sizes: [250]
  })), /* @__PURE__ */ React.createElement(ProductCardContent, null, headline && /* @__PURE__ */ React.createElement(Headline, {
    level: 3,
    small
  }, headline), copy && /* @__PURE__ */ React.createElement(Copy, {
    small
  }, copy), options && options.length > 0 && /* @__PURE__ */ React.createElement(ProductOptionList, null, options.map((o, i) => /* @__PURE__ */ React.createElement(ProductOption, {
    key: i,
    ...o
  }))), url && /* @__PURE__ */ React.createElement(ProductButton, null, t("Zum Produkt"), " ", /* @__PURE__ */ React.createElement(ButtonArrow, null)))));
}
const ProductCardContainer = styled.a`
  transition: box-shadow 0.3s ease;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  text-decoration: none;
  color: inherit;

  &:hover {
    box-shadow: 0px 8px 24px rgba(163, 177, 191, 0.5);
  }

  ${media.mobile} {
    box-shadow: 0px 8px 24px rgba(163, 177, 191, 0.5);
  }

  ${media.tablet} {
    box-shadow: 0px 8px 24px rgba(163, 177, 191, 0.35);
  }
`;
const ProductColorBlock = styled.div`
  background: ${(props) => props.color};
  height: 250px;
  border-radius: 4px 4px 0 0;
  margin-bottom: ${spacings.l}px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  ${media.mobile} {
    margin-bottom: 0;
  }
`;
const ImageContainer = styled(withAttrs(Image, {sizes: [250]}))`
  max-width: 250px;
  height: 100%;
  object-fit: contain;
`;
const Headline = styled(Title)`
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 24px;

  ${({small}) => small && `
        font-size: 24px;
        line-height: 32px;
      `};

  ${media.mobile} {
    margin-bottom: ${spacings.xs}px;
    font-size: 20px;
    line-height: 28px;
  }
`;
const Copy = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: auto;
  color: ${colors.b2};

  ${({small}) => small && `
        font-size: 16px;
        line-height: 24px;
      `};

  ${media.mobile} {
    font-size: 14px;
    line-height: 22px;
  }
`;
const ProductButton = styled(Button)`
  margin-top: ${spacings.l}px;

  ${media.mobile} {
    margin-top: ${spacings.m}px;
    padding: 4px 0;
    width: 100%;
    line-height: 24px;
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`;
const ProductOptionList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  margin-top: 22px;
  grid-gap: 10px 22px;
`;
const ProductCardContent = styled.div`
  padding: ${spacings.l}px;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${media.mobile} {
    padding: ${spacings.m}px;
  }
`;
